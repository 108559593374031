import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../../environments/environment';
import {AuthModel} from '../../_models/auth.model';
import {IVerify} from '../../_models/iverify';

const API_USERS_URL = `${environment.apiUrl}/api`;

@Injectable({
    providedIn: 'root',
})
export class AuthHTTPService {
    constructor(private http: HttpClient) {
    }

    // public methods
    login(email: string, password: string): Observable<any> {
        return this.http.post<AuthModel>(`${environment.apiUrl}/authentication_token`, {email, password});
    }

    // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
    forgotPassword(email: string): Observable<boolean> {
        return this.http.post<boolean>(`${API_USERS_URL}/forgot-password`, {
            email,
        });
    }

    refreshToken(tokenRefresh: string){
        return this.http.post<AuthModel>(`${environment.apiUrl}/api/refresh_token`, {refresh_token: tokenRefresh});
    }

    verify(){
        return this.http.get<IVerify>(`${environment.apiUrl}/api/verify_token`);
    }
}
